import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/forpad/forpad/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "ที่ตั้ง"
    }}>{`ที่ตั้ง`}</h3>
    <ul>
      <li parentName="ul">{`สำนักงานเพื่อแพทย์ ๑๖/๔ ถนนแสงมุกดา ต.ชุมแสง อ.ชุมแสง นครสวรรค์ `}</li>
    </ul>
    <h3 {...{
      "id": "ติดต่อเรา"
    }}>{`ติดต่อเรา:`}</h3>
    <ul>
      <li parentName="ul">{`โทร: ๐๙๒ ๔๖๒๙๖๑๖,๐๘๓ ๔๒๑๗๑๕๔`}</li>
      <li parentName="ul">{`Email: `}<a parentName="li" {...{
          "href": "mailto:oemed001@gmail.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`oemed001@gmail.com`}</a></li>
      <li parentName="ul">{`TikTok : To Science Shop  แปลว่าร้านวิทยาศาสตร์  มีชื่อไทยว่า โตซายน์ชอป`}</li>
      <li parentName="ul">{`Facebook page ชื่อว่า `}<a parentName="li" {...{
          "href": "https://www.facebook.com/forpad.org",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`เพื่อแพทย์`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      